import React, { useState, useEffect } from "react";
import { Tabs, List } from "antd";
import toCurrency from "../core/toCurrency";
import { useTranslation } from "../components/IntlContext/IntlContext";
import Helmet from "react-helmet";
import { facebook2 } from "react-icons-kit/icomoon/facebook2";
import { withBaseIcon } from "react-icons-kit";
import { instagram } from "react-icons-kit/icomoon/instagram";
import { youtube } from "react-icons-kit/icomoon/youtube";
import { whatsapp } from "react-icons-kit/icomoon/whatsapp";
import Button from "@material-ui/core/Button";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom";
import { billService } from "../core/api";
import mask from "../core/mask";
import Axios from "axios";
import {
	createMuiTheme,
	withStyles,
	makeStyles,
	ThemeProvider,
	withTheme,
} from "@material-ui/core/styles";
import formSchema from "../core/formSchema";

const { TabPane } = Tabs;



function isBackgroundDark(hexcolor = "#000000", element = "dont care") {
	hexcolor = hexcolor.replace("#", "");
	let r = parseInt(hexcolor.substr(0, 2), 16);
	let g = parseInt(hexcolor.substr(2, 2), 16);
	let b = parseInt(hexcolor.substr(4, 2), 16);
	let yiq = (r * 299 + g * 587 + b * 114) / 1000;
	return yiq < 128;
}

const LandingPage = () => {
	const [state, setState] = useState({
		company: {},
		plans: {},
		social_media: {}
	});
	const [planArr, setPlanArr] = useState({});
	const [loading, setLoading] = useState(true);
	const instance = Axios.create();

	const t = useTranslation();
	const history = useHistory();

	const HeaderIconContainer = withBaseIcon({
		size: 25,
		style: {
			color: isBackgroundDark(
				state.company ? state.company.primary_color : "#FFFFFF"
			)
				? "white"
				: "black",
			marginRight: 10,
			marginLeft: 10,
		},
	});

	const BootstrapButton = withStyles({
		root: {
			color: isBackgroundDark(state.company.primary_color, "button")
				? "white"
				: "black",
			backgroundColor: state.company.primary_color,
			"&:hover": {
				backgroundColor: `${state.company.primary_color}88`,
			},
		},
	})(Button);


	const IconContainer = withBaseIcon({
		size: 25,
		style: { color: "black", marginRight: 10, marginLeft: 10 },
	});

	const responsive = {
		0: { items: 1 },
		568: { items: 2 },
		1024: { items: 3 },
	};

	const planItems = (plans) => {
		var tempArr = [];
		plans.filter((plan) => {
			tempArr.push(
				<div className="flex-row" style={{ justifyContent: "center" }}>
					<div
						className="item flex-col"
						style={{
							border: "1px solid #d4d4d4",
							borderRadius: "5px",
							height: "fit-content",
							width: 500,
							margin: 20,
							padding: 15,
						}}
					>
						<span style={{ fontSize: 24 }}>{plan.plan.name}</span>
						<p>{plan.plan.description || "Nenhuma descrição disponível"}</p>
						<div
							className="flex-row"
							style={{
								justifyContent: "space-between",
								marginTop: "auto",
							}}
						>
							<span>
								{toCurrency(plan.pricing_option.amount, plan.pricing_option.currency)}/
								{t.frequency_interval_unit[plan.pricing_option.frequency]}
							</span>
							<a
								href={plan.checkout.checkout_url}
								target="_blank"
								style={{
									color: "inherit",
									textDecoration: "none",
								}}
							>
								<BootstrapButton
									variant="contained"
									color="inherit"
								>
									Assinar
								</BootstrapButton>
							</a>
						</div>
					</div>
				</div>
			);
		});
		return tempArr;
	};

	const fetchData = async () => {
		try {
			await billService
				.get("/checkout/landing-page")
				.then((res) => setState(res.data));
		} catch (e) {
			console.log(e);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, []);

	if (!loading)
		return (
			<>
				<div
					className="flex-col"
					style={{ height: "100vh", position: "relative" }}
				>
					<Helmet title={state.company.name}>
						<link
							type="image/png"
							rel="shortcut icon"
							href={`${state.company && state.company.favicon}`}
						/>
					</Helmet>
					<Header state={state} />

					<div
						style={{
							display: "flex",
							flex: 1,
							flexDirection: "column",
							height: "100%",
							backgroundColor: "#fff",
						}}
					>
						<img
							src={state.social_media.front_image_url}
							style={{ width: "100%", maxHeight: "50vh" }}
						/>
						<div
							className="flex-col"
							style={{
								justifyContent: "center",
								textAlign: "center",
							}}
						>
							<h1>{state.social_media.headline.title}</h1>
							<h3 style={{ paddingLeft: 30, paddingRight: 30 }}>
								{state.social_media.headline.text}
							</h3>
						</div>
						<AliceCarousel
							mouseTracking
							items={planItems(state.checkouts)}
							responsive={responsive}
						/>
						<span
							className="flex-col"
							style={{
								justifyContent: "center",
								textAlign: "center",
							}}
						>
							<h2>{t.landing_page['login_prompt1']}</h2>
							<div style={{ marginBottom: 20 }}>
								<BootstrapButton
									variant="contained"
									color="primary"
									onClick={() => history.push("/login")}
								>
									{t.landing_page['login_prompt2']}
								</BootstrapButton>
							</div>
						</span>
						<div
							className="flex-row"
							style={{
								flexWrap: "wrap",
								justifyContent: "space-evenly",
								padding: 20,
								backgroundColor: state.company.primary_color,
							}}
						>
							<div
								className="flex-col"
								style={{
									justifyContent: "center",
									color: isBackgroundDark(
										state.company.primary_color
									)
										? "white"
										: "black",
									// textAlign:
									// 	window.innerWidth < 875
									// 		? "center"
									// 		: "left",
									textAlign: 'center'

								}}

							>
								<h1>{t.landing_page.contacts}</h1>
								{ state.social_media.hide_footer_address ? null :
								<React.Fragment>
									<span style={{ fontSize: 20 }}>
										{t.landing_page.address}: {state.company.street1},{" "}
										{state.company.number}
									</span>
									<span
										style={{ fontSize: 20, marginBottom: 30 }}
									>
										{state.company.postal_code}
									</span>
		
								</React.Fragment>
								}
								<span style={{ fontSize: 20 }}>
									{t.landing_page.phone}:{" "}
									{mask(
										state.social_media.contact_phone[0].match(/\d+/g).join(""),
										formSchema[state.company.country].phoneMask
									)}
								</span>
								<span style={{ fontSize: 20 }}>
									{t.landing_page.email}: {state.social_media.contact_email}
								</span>
								<div style={{ marginTop: 10 }}>
									{state.social_media.social.whatsapp && (
										<a
											target="_blank"
											href={`https://wa.me/${state.social_media.social.whatsapp}`}
										>
											<IconButton size="small">
												<HeaderIconContainer
													icon={whatsapp}
												/>
											</IconButton>
										</a>
									)}
									{state.social_media.social.facebook && (
										<a
											target="_blank"
											href={`${state.social_media.social.facebook}`}
										>
											<IconButton size="small">
												<HeaderIconContainer
													icon={facebook2}
												/>
											</IconButton>
										</a>
									)}
									{state.social_media.social.instagram && (
										<a
											target="_blank"
											href={`${state.social_media.social.instagram}`}
										>
											<IconButton size="small">
												<HeaderIconContainer
													icon={instagram}
												/>
											</IconButton>
										</a>
									)}
									{state.social_media.social.youtube && (
										<a
											target="_blank"
											href={`${state.social_media.social.youtube}`}
										>
											<IconButton size="small">
												<HeaderIconContainer
													icon={youtube}
												/>
											</IconButton>
										</a>
									)}
								</div>
							</div>
						</div>
						<div
							className="flex-row"
							style={{
								backgroundColor: state.company.primary_color,
								fontsize: 25,
								padding: 10,
								justifyContent: "center",
								color: isBackgroundDark(
									state.company.primary_color
								)
									? "white"
									: "black",
							}}
						>
							{state.company.name}® 2024
						</div>
					</div>
				</div>
			</>
		);

	return "";
};

export default LandingPage;

const Header = ({ state }) => {

	const HeaderIconContainer = withBaseIcon({
		size: 25,
		style: {
			color: isBackgroundDark(
				state.company ? state.company.primary_color : "#FFFFFF"
			)
				? "white"
				: "black",
			marginRight: 10,
			marginLeft: 10,
		},
	});

	return (
		<div
			style={{
				display: "flex",
				position: "sticky",
				top: 0,
				padding: 10,
				flexDirection: "row",
				height: "10vh",
				maxHeight: "70px",
				backgroundColor: state.company.primary_color,
				zIndex: 50,
			}}
		>
			{/* <img src={state.company[isBackgroundDark(state.company.primary_color) ? 'logo_white_url' : 'logo_url']} alt="Image" style={{ width: '100%', maxWidth: 150, maxHeight: 50 }} /> */}
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					width: "100%",
					backgroundImage: `url("${state.company[
						isBackgroundDark(state.company.primary_color)
							? "logo_white_url"
							: "logo_url"
					]
						}")`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "contain",
					justifyContent: "flex-end",
				}}
			>
				<div className="flex-row" style={{ alignItems: "center" }}>
					{state.social_media.social.whatsapp && (
						<a
							target="_blank"
							href={`https://wa.me/${state.social_media.social.whatsapp}`}
						>
							<IconButton size="small">
								<HeaderIconContainer icon={whatsapp} />
							</IconButton>
						</a>
					)}
					{state.social_media.social.facebook && (
						<a
							target="_blank"
							href={`${state.social_media.social.facebook}`}
						>
							<IconButton size="small">
								<HeaderIconContainer icon={facebook2} />
							</IconButton>
						</a>
					)}
					{state.social_media.social.instagram && (
						<a
							target="_blank"
							href={`${state.social_media.social.instagram}`}
						>
							<IconButton size="small">
								<HeaderIconContainer icon={instagram} />
							</IconButton>
						</a>
					)}
					{state.social_media.social.youtube && (
						<a
							target="_blank"
							href={`${state.social_media.social.youtube}`}
						>
							<IconButton size="small">
								<HeaderIconContainer icon={youtube} />
							</IconButton>
						</a>
					)}
				</div>
			</div>
		</div>
	);
};
